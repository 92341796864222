import React from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Card, CardContent, Grid, IconButton, InputAdornment, TextField, Typography, Button, Snackbar, Alert } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import LeadGenCard from './LeadGenCard';
import LeadGenStaffCard from './LeadGenStaffCard';
import SponsorsRegCard from './SponsorsRegCard';
import { useState } from 'react';
import { Stack } from '@mui/system';
import { Backspace, Add } from '@mui/icons-material';
import AddSponsorDialog from './AddSponsorDialog';
import UpgradeLimitDialog from './UpgradeLimitDialog';

const GET_SPONSORSREGISTERED_LIST = gql`
    query GetSponsorsRegisteredData($eventId: String!, $searchTerm: String, $role: String) {
        getSponsorsRegisteredData(eventId: $eventId, searchTerm: $searchTerm, role: $role)
    }
`;

const ADD_COMPANY = gql`
    mutation AddCompany($formDetail: String!, $role: String!) {
        addCompany(formDetail: $formDetail, role: $role) {
            status
            message
        }
    }
`;
const UPDATE_COMPANY = gql`
    mutation UpdateSponsor($id: String!, $allowedRegistrations: String, $allowed_no_of_staff: String, $role: String!) {
        updateSponsor(id: $id, allowedRegistrations: $allowedRegistrations, allowed_no_of_staff: $allowed_no_of_staff, role: $role) {
            status
            message
        }
    }
`;

function SearchBox({ onSearch }) {
    const [search, setSearch] = React.useState('');
    React.useEffect(() => {
        const t = setTimeout(() => onSearch(search), 500);
        return () => clearTimeout(t);
    }, [search]);
    return (
        <TextField
            sx={{ width: 300 }}
            label="Search here..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                    <InputAdornment position="end">
                        {search && (
                            <IconButton onClick={() => setSearch('')}>
                                <Backspace />
                            </IconButton>
                        )}
                    </InputAdornment>
                )
            }}
        />
    );
}

export default function SponsorsReg() {
    const { eventId } = useParams();
    const [searchTerm, setSearchTerm] = useState('');
    const role = 'Sponsors';
    const { loading, error, data } = useQuery(GET_SPONSORSREGISTERED_LIST, {
        variables: { eventId, searchTerm, role }
    });
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [upgradeDialogOpen, setUpgradeDialogOpen] = useState(false);
    const [currentLimits, setCurrentLimits] = useState({ allowedRegistrations: 0, allowed_no_of_staff: 0 });

    const [addCompany] = useMutation(ADD_COMPANY);
    const [updateSponsor] = useMutation(UPDATE_COMPANY);

    const sponsorsRegisteredData = (data && data?.getSponsorsRegisteredData && JSON.parse(data?.getSponsorsRegisteredData)) || [];
    console.log(sponsorsRegisteredData);
    const handleAddSponsor = async (formData) => {
        try {
            const response = await addCompany({
                variables: {
                    formDetail: JSON.stringify(formData),
                    role: 'Sponsors'
                },
                refetchQueries: [
                    {
                        query: GET_SPONSORSREGISTERED_LIST,
                        variables: { eventId, searchTerm, role }
                    }
                ]
            });

            if (response.data.addCompany.status === true) {
                setSnackbar({
                    open: true,
                    message: response.data.addCompany.message,
                    severity: 'success'
                });
                setOpenDialog(false);
            } else {
                setSnackbar({
                    open: true,
                    message: response.data.addCompany.message,
                    severity: 'error'
                });
            }
        } catch (error) {
            setSnackbar({
                open: true,
                message: error.message,
                severity: 'error'
            });
        }
    };

    const handleUpdateLimit = async (formData) => {
        // Implement the mutation to update the registration limits
        // Ensure that the new values are greater than the current values
        // Call the appropriate mutation and handle the response
        console.log('formData update', formData, sponsorsRegisteredData[0]?.sponsorId);
        try {
            const response = await updateSponsor({
                variables: {
                    id: sponsorsRegisteredData[0]?.sponsorId,
                    allowedRegistrations: formData.allowedRegistrations.toString(),
                    allowed_no_of_staff: formData.allowed_no_of_staff.toString(),
                    role: 'Sponsors'
                },
                refetchQueries: [
                    {
                        query: GET_SPONSORSREGISTERED_LIST,
                        variables: { eventId, searchTerm, role }
                    }
                ]
            });

            if (response.data.updateSponsor.status === true) {
                setSnackbar({
                    open: true,
                    message: response.data.updateSponsor.message,
                    severity: 'success'
                });
                setUpgradeDialogOpen(false);
            } else {
                setSnackbar({
                    open: true,
                    message: response.data.updateSponsor.message,
                    severity: 'error'
                });
            }
        } catch (error) {
            setSnackbar({
                open: true,
                message: error.message,
                severity: 'error'
            });
        }
    };

    return (
        <div>
            {/* <Typography variant="h2" gutterBottom textAlign="center" sx={{ marginBottom: '20px' }}>
                Sponsors Registered Data
            </Typography> */}
            <Stack
                direction={'row'}
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    // bgcolor: 'background.paper',
                    width: '100%',
                    mb: 5
                }}
            >
                <Typography variant="h1" textAlign={'center'}>
                    Sponsors Data
                </Typography>
                <Stack direction="row" spacing={2} alignItems="center">
                    <SearchBox onSearch={setSearchTerm} />
                    <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => setOpenDialog(true)}>
                        Add Sponsor
                    </Button>
                </Stack>
            </Stack>
            <Grid container spacing={2} justifyContent="center">
                {sponsorsRegisteredData.length > 0 ? (
                    sponsorsRegisteredData.map((sponsorData, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={2} key={index}>
                            <SponsorsRegCard
                                sponsorData={sponsorData}
                                onUpdateLimit={(data) => {
                                    // Extract the allowed registration limits for VIP and Delegate
                                    const vipData = data.registrationData.data.find((item) => item.name === 'vip');
                                    const delegateData = data.registrationData.data.find((item) => item.name === 'delegate');

                                    // Ensure values exist before setting state
                                    setCurrentLimits({
                                        allowedRegistrations: vipData ? vipData.allowedRegistration : 0,
                                        allowed_no_of_staff: delegateData ? delegateData.allowedRegistration : 0
                                    });

                                    setUpgradeDialogOpen(true);
                                }}
                            />
                        </Grid>
                    ))
                ) : (
                    <Typography textAlign="center">Oops! No data found</Typography>
                )}
            </Grid>

            <AddSponsorDialog open={openDialog} onClose={() => setOpenDialog(false)} onSubmit={handleAddSponsor} />

            <UpgradeLimitDialog
                open={upgradeDialogOpen}
                onClose={() => setUpgradeDialogOpen(false)}
                onSubmit={handleUpdateLimit}
                currentLimits={currentLimits}
            />

            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
}
