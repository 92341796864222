import { Button, Card, CardContent, Divider, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';

export default function CorporatesRegCard({ corporateData }) {
    const navigate = useNavigate();

    return (
        <Card variant="outlined">
            <CardContent>
                <Stack direction={'row'} justifyContent={'center'} padding={1}>
                    {/* <Typography variant="body2" justifyContent={'start'} fontWeight={700} color="text.secondary">
                            Company:
                        </Typography> */}
                    <Typography variant="span" justifyContent={'center'} fontWeight={'bold'}>
                        {corporateData?.company}
                    </Typography>
                </Stack>
                <Divider />

                <Stack direction={'row'} justifyContent={'space-between'} padding={1}>
                    <Typography variant="body2" justifyContent={'start'} fontWeight={700} color="text.secondary">
                        Total No Of Registration:
                    </Typography>
                    <Typography variant="span" justifyContent={'end'} sx={{ cursor: 'pointer' }}>
                        <Tooltip title="completed registration">{corporateData.registrationData.total} </Tooltip> /{' '}
                        <Tooltip title="allowed Registration"> {corporateData.registrationData.totalAllowedRegistration || 0} </Tooltip>
                    </Typography>
                </Stack>
                <Divider />
                <Stack direction={'row'} justifyContent={'space-between'} padding={1}>
                    <Typography variant="body2" justifyContent={'start'} fontWeight={700} color="text.secondary">
                        Total No of Entry:
                    </Typography>
                    <Typography variant="span" justifyContent={'end'}>
                        {corporateData.scannedData.total.entry}
                    </Typography>
                </Stack>
                <Divider />
                <Stack direction={'row'} justifyContent={'space-between'} padding={1}>
                    <Typography variant="body2" justifyContent={'start'} fontWeight={700} color="text.secondary">
                        Total No of Prints:
                    </Typography>
                    <Typography variant="span" justifyContent={'end'}>
                        {corporateData.printedData.total}
                    </Typography>
                </Stack>
                <Divider />

                <Stack spacing={2} mt={2} justifyContent={'center'}>
                    <Button
                        variant={'contained'}
                        color="secondary"
                        onClick={() => navigate(`${corporateData.corporateId}`, { state: { corporateData } })}
                    >
                        View Data
                    </Button>
                </Stack>
            </CardContent>
        </Card>
    );
}
