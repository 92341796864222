import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export default function UpgradeLimitDialog({ open, onClose, onSubmit, currentLimits }) {
    console.log(currentLimits);

    // Define validation schema dynamically based on initial values
    const validationSchema = Yup.object({
        allowedRegistrations: Yup.number()
            .required('Allowed registrations for VIP is required')
            .min(
                currentLimits.allowedRegistrations,
                `Must be at greater then previous allowed ${currentLimits.allowedRegistrations} registration`
            )
            .integer('Must be a whole number'),
        allowed_no_of_staff: Yup.number()
            .required('Allowed registrations for Delegate is required')
            .min(
                currentLimits.allowed_no_of_staff,
                `Must be at greater then previous allowed ${currentLimits.allowed_no_of_staff} registration`
            )
            .integer('Must be a whole number')
    });

    const formik = useFormik({
        initialValues: {
            allowedRegistrations: currentLimits.allowedRegistrations,
            allowed_no_of_staff: currentLimits.allowed_no_of_staff
        },
        validationSchema,
        onSubmit: (values) => {
            onSubmit(values);
        }
    });

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Upgrade Registration Limit</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            fullWidth
                            name="allowedRegistrations"
                            label="Allowed Registrations for VIP"
                            type="number"
                            value={formik.values.allowedRegistrations}
                            onChange={formik.handleChange}
                            error={formik.touched.allowedRegistrations && Boolean(formik.errors.allowedRegistrations)}
                            helperText={formik.touched.allowedRegistrations && formik.errors.allowedRegistrations}
                        />
                        <TextField
                            fullWidth
                            name="allowed_no_of_staff"
                            label="Allowed Registrations for Delegate"
                            type="number"
                            value={formik.values.allowed_no_of_staff}
                            onChange={formik.handleChange}
                            error={formik.touched.allowed_no_of_staff && Boolean(formik.errors.allowed_no_of_staff)}
                            helperText={formik.touched.allowed_no_of_staff && formik.errors.allowed_no_of_staff}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                        Upgrade
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
