import * as React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
    AppBar,
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    LinearProgress,
    Stack,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
    Snackbar,
    Alert
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Backspace, BadgeTwoTone, DeleteForever, DeleteTwoTone, Upgrade } from '@mui/icons-material';
import CertificateIcon from '@mui/icons-material/WorkspacePremium';
import AnimateButton from 'ui-component/extended/AnimateButton';

import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';

import PropTypes from 'prop-types';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import SendEmail from 'Components/SendEmail';
import { set } from 'store';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    };
}

export default function VerificationPage() {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [snackbar, setSnackbar] = React.useState({ open: false, message: '', severity: 'success' });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    const { eventId, role } = useParams();
    const [search, setSearch] = React.useState('');
    const { data, loading, error } = useQuery(GET_ME);
    const [tags, setTags] = React.useState([]);
    const { data: featureData, loading: featureLoading } = useQuery(GET_VERIFICATION_FEATURE, {
        variables: { eventId, role }
    });
    React.useEffect(() => {
        if (featureData && featureData.mailVerificationFeature) {
            let parsedData = [];
            try {
                parsedData = JSON.parse(featureData.mailVerificationFeature);
            } catch (err) {
                console.error('Error parsing the data:', err);
            }
            if (Array.isArray(parsedData)) {
                setTags(parsedData);
            }
        }
    }, [featureData]);
    if (featureLoading) return <LinearProgress />;

    if (loading) {
        return <LinearProgress />;
    }
    if (error) {
        return <Typography>{error.message}</Typography>;
    }

    return (
        <Stack spacing={2}>
            <MainCard>
                <Grid spacing={gridSpacing} container={true}>
                    <Grid item xs={12}>
                        <Stack direction={'row'} justifyContent="space-between" sx={{ bgcolor: 'background.paper', width: '100%', mb: 2 }}>
                            <Typography variant="h1" textAlign={'center'}>
                                Verify {role} Data
                            </Typography>
                            {data?.me?.permissions?.includes('find-' + role) && <SearchBox onSearch={setSearch} />}
                        </Stack>

                        <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
                            <AppBar position="relative" color="secondary">
                                <Tabs
                                    sx={{
                                        '& .Mui-selected': { background: '#05344f', color: 'white' },
                                        background: 'white',
                                        color: 'black'
                                    }}
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="secondary"
                                    textColor="inherit"
                                    variant="fullWidth"
                                    aria-label="full width tabs example"
                                >
                                    <Tab
                                        sx={{ '& .Mui-selected': { background: '#05344f' }, border: '1px solid black' }}
                                        label="Accepted Registration"
                                        {...a11yProps(0)}
                                    />
                                    <Tab
                                        sx={{ '& .Mui-selected': { background: '#05344f' }, border: '1px solid black' }}
                                        label="Pending Registration"
                                        {...a11yProps(1)}
                                    />
                                    <Tab
                                        sx={{ '& .Mui-selected': { background: '#05344f' }, border: '1px solid black' }}
                                        label="Rejected Registration"
                                        {...a11yProps(2)}
                                    />
                                </Tabs>
                            </AppBar>
                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={value}
                                onChangeIndex={handleChangeIndex}
                            >
                                <TabPanel value={value} index={0} dir={theme.direction}>
                                    <DataTable
                                        {...{ eventId, search, role }}
                                        tags={tags}
                                        status="accept"
                                        permissions={data.me?.permissions?.split(',') || []}
                                        setSnackbar={setSnackbar}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={1} dir={theme.direction}>
                                    <DataTable
                                        {...{ eventId, search, role }}
                                        tags={tags}
                                        status="pending"
                                        permissions={data.me?.permissions?.split(',') || []}
                                        setSnackbar={setSnackbar}
                                    />
                                </TabPanel>
                                <TabPanel value={value} index={2} dir={theme.direction}>
                                    <DataTable
                                        {...{ eventId, search, role }}
                                        tags={tags}
                                        status="reject"
                                        permissions={data.me?.permissions?.split(',') || []}
                                        setSnackbar={setSnackbar}
                                    />
                                </TabPanel>
                            </SwipeableViews>
                        </Box>
                    </Grid>
                </Grid>
            </MainCard>
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Stack>
    );
}

function SearchBox({ onSearch }) {
    const [search, setSearch] = React.useState('');
    React.useEffect(() => {
        const t = setTimeout(() => onSearch(search), 500);
        return () => clearTimeout(t);
    }, [search]);
    return (
        <TextField
            sx={{ width: 600 }}
            label="Search here..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                    <InputAdornment position="end">
                        {search && (
                            <IconButton onClick={() => setSearch('')}>
                                <Backspace />
                            </IconButton>
                        )}
                    </InputAdornment>
                )
            }}
        />
    );
}

function DataTable({ eventId, role, search = '', permissions, tags, status, setSnackbar }) {
    const limit = 100;
    const { data, loading, error, refetch } = useQuery(GET_VERIFIED_DATA, {
        variables: {
            eventId,
            role,
            status,
            conditions: {
                limit,
                text: search,
                orderBy: 'created',
                order: 'desc'
            }
        }
    });
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const [currentAction, setCurrentAction] = React.useState({});
    const [noOfParticipant, setNoOfParticipant] = React.useState(0);
    const [paidSeats, setPaidSeats] = React.useState(0);
    const [freeSeats, setFreeSeats] = React.useState(0);
    const [discount, setDiscount] = React.useState(0);
    const [isLoading, setIsLoading] = React.useState(false);
    const [selectedIds, setSelectedIds] = React.useState([]);
    const [modalMessage, setModalMessage] = React.useState('');
    const [upgradeParticipant, setUpgradeParticipant] = React.useState(null);

    const handleAcceptReject = (userData, value) => {
        console.log(userData);
        setUpgradeParticipant(null);

        setCurrentAction({
            id: userData.id,
            value,
            no_of_participant: userData?.no_of_participant || 0,
            freeSeats: userData?.freeSeats || 0,
            paidSeats: userData?.paidSeats || 0,
            discount: userData?.discount || 0
        });
        if (value === 'accept') {
            if (tags?.includes('tokenLink')) {
                console.log(userData?.no_of_participant);
                setNoOfParticipant(userData?.no_of_participant);
                setModalMessage(
                    <>
                        You are about to accept this application. Please specify the details below:
                        <br />
                        - **No of Participants**: Enter the total number of participants that will be allowed.
                        <br />
                        - **Paid Seats**: Enter the number of seats that will be paid for.
                        <br />
                        - **Free Seats**: Enter the number of seats that will be provided for free.
                        <br />- **Discount (if applicable)**: Enter any discount that may apply to the registration.
                    </>
                );
            } else {
                setModalMessage(
                    <>
                        Are you sure you wish to <b>Accept</b> this application?
                        <br />
                        <br />
                        An <b>Email</b> will be sent to the attendee upon <b>Acceptance</b>.
                    </>
                );
            }
        } else if (value === 'reject') {
            if (tags?.includes('tokenLink')) {
                setModalMessage(
                    <>
                        Are you sure you wish to <b>Reject</b> this application?
                        <br />
                        <br />
                        An <b>Email</b> will be sent to the attendee upon <b>Rejection</b>.
                    </>
                );
            } else {
                setModalMessage(
                    <>
                        Are you sure you wish to <b>Reject</b> this application?
                        <br />
                        <br />
                        An <b>Email</b> will be sent to the attendee upon <b>Rejection</b>.
                    </>
                );
            }
        } else if (value === 'upgrade') {
            setNoOfParticipant(userData?.no_of_participant);
            setModalMessage(
                <>
                    You are about to upgrade the registration limit for this application. Please specify the details below:
                    <br />
                    - **Add No of Participants**: Enter the number of additional participants to authorize.
                    <br />
                    - **Paid Seats**: Enter the number of seats that will be paid for.
                    <br />
                    - **Free Seats**: Enter the number of seats that will be provided for free.
                    <br />- **Discount (if applicable)**: Enter any discount that may apply to the registration.
                </>
            );
        }
        setIsModalOpen(true);
    };

    const handleNoofParticipate = (value, action) => {
        if (action === 'accept') {
            setNoOfParticipant(value);
        } else if (action === 'upgrade') {
            setUpgradeParticipant(value);
        } else if (action === 'reject') {
            setNoOfParticipant(value);
        }
    };

    const confirmAction = () => {
        const totalSeats = parseInt(paidSeats || 0) + parseInt(freeSeats || 0);
        const currentParticipants = currentAction.value === 'upgrade' ? parseInt(upgradeParticipant) : parseInt(noOfParticipant);
        const currentNewParticipants =
            currentAction.value === 'upgrade' ? parseInt(upgradeParticipant) + parseInt(noOfParticipant) : parseInt(noOfParticipant);
        const currentfreeSeats =
            currentAction.value === 'upgrade'
                ? parseInt(freeSeats || 0) + parseInt(currentAction.freeSeats || 0)
                : parseInt(freeSeats || 0);
        const currentpaidSeats =
            currentAction.value === 'upgrade'
                ? parseInt(paidSeats || 0) + parseInt(currentAction.paidSeats || 0)
                : parseInt(paidSeats || 0);
        const currentDiscount =
            currentAction.value === 'upgrade' ? parseInt(discount || 0) + parseInt(currentAction.discount || 0) : parseInt(discount || 0);

        // Validate the sum of paid and free seats
        if (totalSeats !== parseInt(currentParticipants)) {
            setSnackbar({
                open: true,
                message: `The sum of Paid Seats and Free Seats must equal No of Participants (${currentParticipants}).`,
                severity: 'error'
            });
            return;
        }

        setIsLoading(true);

        // Prepare variables for the mutation
        const variables = {
            eventId,
            role,
            id: currentAction.id,
            field: currentAction.value === 'accept' ? 'status' : 'upgrade',
            value: currentAction.value,
            no_of_participant: currentNewParticipants.toString(),
            ...(tags?.includes('tokenLink') && {
                paidSeats: currentpaidSeats.toString(),
                freeSeats: currentfreeSeats.toString(),
                discount: currentDiscount.toString()
            })
        };

        updateApplicantStatusandMail({ variables })
            .then((response) => {
                setSnackbar({ open: true, message: response?.data?.result || 'Updated successfully.', severity: 'success' });
                setIsLoading(false);
                setIsModalOpen(false);
                refetch(); // Assuming you want to refetch data after the update
            })
            .catch((error) => {
                setSnackbar({
                    open: true,
                    message: error.message || "Something went wrong. Couldn't perform the action.",
                    severity: 'error'
                });
                setIsLoading(false);
            });
    };
    const [updateApplicantStatusandMail] = useMutation(UPDATE_APPLICANT_STATUS_AND_MAIL, {
        onCompleted: () => refetch(),
        onError: (e) => {
            refetch();
            console.log(e);
        }
    });
    const [updateField] = useMutation(UPDATE_APPLICANT_FIELD, {
        onCompleted: () => refetch(),
        onError: (e) => console.log(e)
    });
    const [doDelete] = useMutation(DELETE_APPLICANT, { onCompleted: () => refetch() });

    if (loading) {
        return <LinearProgress />;
    }
    if (error) {
        return <Typography color="error">{error.message}</Typography>;
    }
    return (
        <Stack spacing={2}>
            <Box>
                <Stack direction="row" spacing={2}>
                    <AnimateButton>
                        <Button variant="contained" disabled={loading} onClick={() => refetch()}>
                            Refresh
                        </Button>
                    </AnimateButton>

                    {permissions.includes('admin') && permissions.includes('bulk-email') ? (
                        <SendEmail {...{ selectedIds, eventId, role }} />
                    ) : (
                        <div />
                    )}
                </Stack>
            </Box>
            <Box>
                <Dialog open={isModalOpen} onClose={() => setIsModalOpen(false)} aria-labelledby="message-dialog-title">
                    <DialogTitle id="message-dialog-title" sx={{ fontSize: 24 }}>
                        {!!currentAction.id ? 'Confirm Action' : 'Message'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>{modalMessage}</DialogContentText>
                        {tags?.includes('tokenLink') && currentAction.value === 'accept' && (
                            <Stack spacing={2} sx={{ m: 2 }}>
                                <TextField
                                    type="number"
                                    label="No of Participants"
                                    value={noOfParticipant}
                                    onChange={(e) => handleNoofParticipate(e.target.value, currentAction.value)}
                                />
                                <TextField
                                    type="number"
                                    label="Paid Seats"
                                    value={paidSeats}
                                    onChange={(e) => setPaidSeats(e.target.value)}
                                />
                                <TextField
                                    type="number"
                                    label="Free Seats"
                                    value={freeSeats}
                                    onChange={(e) => setFreeSeats(e.target.value)}
                                />
                                <TextField
                                    type="number"
                                    label="Discount (if applicable)"
                                    value={discount}
                                    onChange={(e) => setDiscount(e.target.value)}
                                />
                            </Stack>
                        )}
                        {tags?.includes('tokenLink') && currentAction.value === 'upgrade' && (
                            <Stack spacing={2} sx={{ m: 2 }}>
                                <Typography variant="body2">
                                    Current participant limit: {currentAction.no_of_participant}. Please specify the number of additional
                                    attendees to authorize:
                                </Typography>
                                <TextField
                                    type="number"
                                    label="Add No of Participants"
                                    value={upgradeParticipant}
                                    onChange={(e) => handleNoofParticipate(e.target.value, currentAction.value)}
                                />
                                <TextField
                                    type="number"
                                    label="Paid Seats"
                                    value={paidSeats}
                                    onChange={(e) => setPaidSeats(e.target.value)}
                                />
                                <TextField
                                    type="number"
                                    label="Free Seats"
                                    value={freeSeats}
                                    onChange={(e) => setFreeSeats(e.target.value)}
                                />
                                <TextField
                                    type="number"
                                    label="Discount (if applicable)"
                                    value={discount}
                                    onChange={(e) => setDiscount(e.target.value)}
                                />
                            </Stack>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setIsModalOpen(false)} variant="outlined" color="error">
                            Close
                        </Button>
                        {!!currentAction.id && (
                            <Button onClick={confirmAction} variant="contained" color="success">
                                {isLoading ? <CircularProgress color="primary" size={20} /> : 'Confirm'}
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>

                <DataGrid
                    sx={{ height: 600 }}
                    paginationMode="server"
                    pageSize={limit}
                    rowsPerPageOptions={[limit]}
                    onPageChange={(p) =>
                        refetch({
                            conditions: {
                                offset: p * limit,
                                limit,
                                orderBy: 'created',
                                order: 'desc'
                            }
                        })
                    }
                    rowCount={data?.total || 0}
                    checkboxSelection={permissions.includes('admin') || permissions.includes('email')}
                    onSelectionModelChange={(ids) => {
                        setSelectedIds(
                            ids.map((id) => {
                                const applicant = JSON.parse(
                                    data?.verifiedApplicants?.find((d) => d.id === id)?.data || '{"email":"undefined"}'
                                );
                                return {
                                    id,
                                    email: applicant.email || applicant.Email
                                };
                            })
                        );
                    }}
                    onCellEditCommit={(p, e) =>
                        e.key === 'Enter' &&
                        updateField({
                            variables: {
                                eventId,
                                role,
                                id: p.id,
                                field: p.field,
                                value: p.value
                            }
                        })
                            .then((r) => window.alert(r?.data?.result || 'updated successfully'))
                            .catch((e) => window.alert(e.message))
                    }
                    components={{
                        Toolbar: permissions.includes('exp-' + role) ? GridToolbar : ''
                    }}
                    columns={[
                        {
                            type: 'actions',
                            field: 'actions',
                            headerName: 'Actions',
                            width: 150,
                            getActions: (p) =>
                                [
                                    permissions.includes('del-' + role) && (
                                        <GridActionsCellItem
                                            label="Delete"
                                            onClick={() => {
                                                if (!window.confirm('Are you sure you want to delete this?')) {
                                                    return;
                                                }
                                                doDelete({ variables: { eventId, role, id: p.id } })
                                                    .catch((e) => window.alert(e.message))
                                                    .then((r) => window.alert(r.data.result));
                                            }}
                                            icon={<DeleteTwoTone color="error" />}
                                        />
                                    ),
                                    permissions.includes('admin') && tags.includes('emailVerification') && (
                                        <React.Fragment>
                                            {(p.row.status === 'pending' || p.row.status === 'reject') && (
                                                <Tooltip title="Accept Registration">
                                                    <GridActionsCellItem
                                                        label="Accept"
                                                        onClick={() => handleAcceptReject(p.row, 'accept')}
                                                        icon={<ThumbUpOffAltIcon color="success" />}
                                                    />
                                                </Tooltip>
                                            )}
                                            {(p.row.status === 'pending' || p.row.status === 'accept') && (
                                                <Tooltip title="Reject Registration">
                                                    <GridActionsCellItem
                                                        label="Reject"
                                                        onClick={() => handleAcceptReject(p.row, 'reject')}
                                                        icon={<ThumbDownOffAltIcon color="error" />}
                                                    />
                                                </Tooltip>
                                            )}
                                        </React.Fragment>
                                    ),
                                    permissions.includes('admin') &&
                                        permissions.includes('upgrade-registration') &&
                                        role === 'Corporate' && (
                                            <React.Fragment>
                                                {p.row.status === 'accept' && (
                                                    <Tooltip title="Upgrade Registration Limit">
                                                        <GridActionsCellItem
                                                            label="Upgrade Limit"
                                                            onClick={() => handleAcceptReject(p.row, 'upgrade')}
                                                            icon={<Upgrade color="success" />}
                                                        />
                                                    </Tooltip>
                                                )}
                                            </React.Fragment>
                                        )
                                ].filter(Boolean)
                        },
                        { field: 'id', width: 150 },

                        {
                            field: 'created',
                            width: 200,
                            valueFormatter: (row) => format(+row.value, 'dd-MMM-yyyy hh:mm a')
                        },
                        { field: 'regLink', width: 300, headerName: 'Registration Link' },

                        ...data?.form.fields.map((f) => ({
                            field: f.name,
                            width: 200,
                            editable: permissions.includes('edit-' + role)
                        }))
                    ]}
                    rows={data?.verifiedApplicants.map((row) => ({
                        ...row,
                        ...JSON.parse(row.data)
                    }))}
                />
            </Box>
        </Stack>
    );
}

export const GET_VERIFIED_DATA = gql`
    query ($eventId: String!, $role: ROLE!, $status: String, $conditions: InputConditions) {
        verifiedApplicants(eventId: $eventId, role: $role, status: $status, conditions: $conditions) {
            serial
            id
            created
            regLink
            data
        }
        total: verifiedApplicantsCount(eventId: $eventId, role: $role, status: $status, conditions: $conditions)

        form(eventId: $eventId, role: $role) {
            fields {
                name
            }
        }
    }
`;

const UPDATE_APPLICANT_FIELD = gql`
    mutation ($eventId: String!, $role: ROLE!, $id: String!, $field: String!, $value: String!) {
        result: updateApplicantField(eventId: $eventId, role: $role, id: $id, field: $field, value: $value)
    }
`;
const UPDATE_APPLICANT_STATUS_AND_MAIL = gql`
    mutation (
        $eventId: String!
        $role: ROLE!
        $id: String!
        $field: String!
        $value: String!
        $no_of_participant: String
        $paidSeats: String
        $freeSeats: String
        $discount: String
    ) {
        result: updateApplicantStatusandMail(
            eventId: $eventId
            role: $role
            id: $id
            field: $field
            value: $value
            no_of_participant: $no_of_participant
            paidSeats: $paidSeats
            freeSeats: $freeSeats
            discount: $discount
        )
    }
`;

const DELETE_APPLICANT = gql`
    mutation ($eventId: String!, $role: ROLE!, $id: String!) {
        result: deleteApplicant(eventId: $eventId, role: $role, id: $id)
    }
`;

const GET_ME = gql`
    query {
        me {
            permissions
        }
    }
`;
export const GET_VERIFICATION_FEATURE = gql`
    query ($eventId: String!, $role: ROLE!) {
        mailVerificationFeature(eventId: $eventId, role: $role)
    }
`;
