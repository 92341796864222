import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
    Company: Yup.string().required('Company name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    allowedRegistrations: Yup.number()
        .required('Number of allowed registrations is required')
        .min(1, 'Must be at least 1')
        .integer('Must be a whole number'),
    stallNo: Yup.string().required('Booth number is required')
});

export default function AddCompanyDialog({ open, onClose, onSubmit }) {
    const formik = useFormik({
        initialValues: {
            Company: '',
            email: '',
            allowedRegistrations: '',
            stallNo: ''
        },
        validationSchema,
        onSubmit: (values) => {
            onSubmit(values);
        }
    });

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Add New Company</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            fullWidth
                            name="Company"
                            label="Company Name"
                            value={formik.values.Company}
                            onChange={formik.handleChange}
                            error={formik.touched.Company && Boolean(formik.errors.Company)}
                            helperText={formik.touched.Company && formik.errors.Company}
                        />
                        <TextField
                            fullWidth
                            name="email"
                            label="Email"
                            type="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <TextField
                            fullWidth
                            name="allowedRegistrations"
                            label="Allowed Registrations"
                            type="number"
                            value={formik.values.allowedRegistrations}
                            onChange={formik.handleChange}
                            error={formik.touched.allowedRegistrations && Boolean(formik.errors.allowedRegistrations)}
                            helperText={formik.touched.allowedRegistrations && formik.errors.allowedRegistrations}
                        />
                        <TextField
                            fullWidth
                            name="stallNo"
                            label="Booth Number"
                            value={formik.values.stallNo}
                            onChange={formik.handleChange}
                            error={formik.touched.stallNo && Boolean(formik.errors.stallNo)}
                            helperText={formik.touched.stallNo && formik.errors.stallNo}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                        Add Company
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
