import React, { useState } from 'react';
import {
    Button,
    Snackbar,
    Alert,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Chip,
    TextField,
    InputAdornment,
    IconButton,
    Tooltip,
    CircularProgress,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';
import {
    Add as AddIcon,
    Edit as EditIcon,
    Delete as DeleteIcon,
    Search as SearchIcon,
    Refresh as RefreshIcon,
    ContentCopy as CopyIcon
} from '@mui/icons-material';
import { useQuery, useMutation, gql } from '@apollo/client';
import AddCouponDialog from './AddCouponDialog';
import { format } from 'date-fns';
import { useParams } from 'react-router';

const CouponManager = () => {
    const { eventId } = useParams();
    const { loading, error, data, refetch } = useQuery(GET_COUPONS, {
        variables: {
            eventId
        }
    });
    const [addCoupon] = useMutation(ADD_COUPON);
    const [updateCoupon] = useMutation(UPDATE_COUPON);
    const [deleteCoupon] = useMutation(DELETE_COUPON);
    const [toggleStatus] = useMutation(TOGGLE_COUPON_STATUS);

    // State management
    const [openDialog, setOpenDialog] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [selectedCoupon, setSelectedCoupon] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [couponToDelete, setCouponToDelete] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: 'CouponTitle', direction: 'asc' });
    // Update handleAddCoupon function
    const handleAddCoupon = async (formData) => {
        try {
            const response = await addCoupon({
                variables: {
                    input: {
                        CouponTitle: formData.CouponTitle,
                        CouponCode: formData.CouponCode.toUpperCase(), // Force uppercase
                        CouponValue: parseFloat(formData.CouponValue),
                        CouponType: '%',
                        ApplicableTimes: parseInt(formData.ApplicableTimes),
                        Status: formData.Status
                    },
                    eventId
                }
            });

            if (response.data.addCoupon.status) {
                setSnackbar({
                    open: true,
                    message: 'Coupon created successfully',
                    severity: 'success'
                });
                setOpenDialog(false);
                refetch();
            } else {
                throw new Error(response.data.addCoupon.message);
            }
        } catch (error) {
            if (error.message.includes('already exists')) {
                // Let the dialog handle this specific error
                throw error;
            }
            setSnackbar({
                open: true,
                message: error.message,
                severity: 'error'
            });
        }
    };

    const handleEditCoupon = async (formData) => {
        try {
            const response = await updateCoupon({
                variables: {
                    id: parseInt(selectedCoupon.id),
                    input: {
                        CouponTitle: formData.CouponTitle,
                        CouponCode: formData.CouponCode,
                        CouponValue: parseFloat(formData.CouponValue),
                        CouponType: formData.CouponType,
                        ApplicableTimes: parseInt(formData.ApplicableTimes),
                        Status: formData.Status
                    },
                    eventId
                }
            });
            if (response.data.updateCoupon.status) {
                setSnackbar({ open: true, message: response.data.updateCoupon.message, severity: 'success' });
                setOpenDialog(false);
                refetch();
            }
        } catch (error) {
            setSnackbar({ open: true, message: error.message, severity: 'error' });
        }
    };

    // const handleDeleteCoupon = async (id) => {
    //     if (window.confirm('Are you sure you want to delete this coupon?')) {
    //         try {
    //             const response = await deleteCoupon({ variables: { id } });
    //             if (response.data.deleteCoupon.status) {
    //                 setSnackbar({ open: true, message: response.data.deleteCoupon.message, severity: 'success' });
    //                 refetch();
    //             }
    //         } catch (error) {
    //             setSnackbar({ open: true, message: error.message, severity: 'error' });
    //         }
    //     }
    // };
    // Enhanced filtering and sorting
    const filteredCoupons = () => {
        let coupons = data?.getCoupons ? [...data.getCoupons] : [];

        // Search filter
        if (searchQuery) {
            coupons = coupons.filter((coupon) =>
                Object.values(coupon).some((value) => String(value).toLowerCase().includes(searchQuery.toLowerCase()))
            );
        }

        // Sorting - create a new array for sorting
        if (sortConfig.key) {
            coupons = [...coupons].sort((a, b) => {
                const aValue = a[sortConfig.key];
                const bValue = b[sortConfig.key];

                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    return sortConfig.direction === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
                }

                return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
            });
        }

        return coupons;
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    // Enhanced delete confirmation
    const handleDeleteConfirmation = (id) => {
        setCouponToDelete(id);
        setDeleteConfirmOpen(true);
    };

    const handleDelete = async () => {
        try {
            const response = await deleteCoupon({ variables: { id: couponToDelete, eventId } });
            if (response.data.deleteCoupon.status) {
                setSnackbar({ open: true, message: response.data.deleteCoupon.message, severity: 'success' });
                refetch();
            }
        } catch (error) {
            setSnackbar({ open: true, message: error.message, severity: 'error' });
        }
        setDeleteConfirmOpen(false);
    };
    const handleToggleStatus = async (id) => {
        try {
            const response = await toggleStatus({
                variables: { eventId, id },
                update: (cache, { data }) => {
                    if (data.toggleCouponStatus.status) {
                        cache.modify({
                            fields: {
                                getCoupons(existingCoupons = [], { readField }) {
                                    return existingCoupons.map((couponRef) => {
                                        if (readField('id', couponRef) === id) {
                                            return {
                                                ...couponRef,
                                                Status: data.toggleCouponStatus.coupon.Status
                                            };
                                        }
                                        return couponRef;
                                    });
                                }
                            }
                        });
                    }
                }
            });

            if (response.data.toggleCouponStatus.status) {
                setSnackbar({
                    open: true,
                    message: 'Status updated successfully',
                    severity: 'success'
                });
            }
        } catch (error) {
            setSnackbar({ open: true, message: error.message, severity: 'error' });
        }
    };
    const StatusChip = ({ status, onClick }) => (
        <Tooltip title={status === 'ACTIVE' ? 'Change status to Inactive' : 'Change status to Active'}>
            <Chip
                label={status}
                color={status === 'ACTIVE' ? 'success' : 'error'}
                size="small"
                variant="outlined"
                onClick={onClick}
                style={{ cursor: 'pointer' }}
            />
        </Tooltip>
    );
    // UI Components
    // const StatusChip = ({ status }) => (
    //     <Chip label={status} color={status === 'Active' ? 'success' : 'error'} size="small" variant="outlined" />
    // );

    const TableHeaderCell = ({ label, sortKey }) => (
        <TableCell
            sx={{ fontWeight: 'bold', cursor: 'pointer', '&:hover': { backgroundColor: 'action.hover' } }}
            onClick={() => handleSort(sortKey)}
        >
            {label}
            {sortConfig.key === sortKey && <span style={{ marginLeft: 4 }}>{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>}
        </TableCell>
    );

    const handleCopyToClipboard = (couponCode) => {
        navigator.clipboard
            .writeText(couponCode)
            .then(() => {
                setSnackbar({ open: true, message: 'Coupon code copied to clipboard!', severity: 'success' });
            })
            .catch((err) => {
                setSnackbar({ open: true, message: 'Failed to copy coupon code!', severity: 'error' });
            });
    };

    if (error) return <Alert severity="error">Error loading coupons: {error.message}</Alert>;

    return (
        <Paper sx={{ p: 3, borderRadius: 2 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold' }}>
                    Coupon Management
                </Typography>

                <div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
                    <TextField
                        variant="outlined"
                        size="small"
                        placeholder="Search coupons..."
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />

                    <Tooltip title="Refresh list">
                        <IconButton onClick={() => refetch()}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>

                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            setSelectedCoupon(null);
                            setOpenDialog(true);
                        }}
                    >
                        New Coupon
                    </Button>
                </div>
            </div>

            <TableContainer sx={{ maxHeight: '60vh', overflow: 'auto', border: 1, borderColor: 'divider' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableHeaderCell label="Title" sortKey="CouponTitle" />
                            <TableHeaderCell label="Code" sortKey="CouponCode" />
                            <TableHeaderCell label="Value" sortKey="CouponValue" />
                            <TableHeaderCell label="Usage" sortKey="UsedTimes" />
                            <TableHeaderCell label="Status" sortKey="Status" />
                            <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={7} align="center">
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        ) : (
                            filteredCoupons().map((coupon) => (
                                <TableRow key={coupon.id} hover>
                                    <TableCell>{coupon.CouponTitle}</TableCell>
                                    <TableCell>
                                        <Chip label={coupon.CouponCode} variant="outlined" />
                                        <IconButton onClick={() => handleCopyToClipboard(coupon.CouponCode)}>
                                            <Tooltip title="Copy Coupon Code">
                                                <CopyIcon fontSize="small" />
                                            </Tooltip>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell>{coupon.CouponType === '%' ? `${coupon.CouponValue}%` : `$${coupon.CouponValue}`}</TableCell>
                                    {/* <TableCell>{coupon.CouponType}</TableCell> */}
                                    <TableCell>
                                        {coupon.UsedTimes}/{coupon.ApplicableTimes}
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip
                                            title={coupon.Status === 'active' ? 'Change status to Inactive' : 'Change status to Active'}
                                        >
                                            <StatusChip
                                                status={coupon.Status?.toUpperCase()}
                                                onClick={() => handleToggleStatus(coupon.id)}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <div style={{ display: 'flex', gap: 8 }}>
                                            {/* <Tooltip title="Edit">
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        setSelectedCoupon(coupon);
                                                        setOpenDialog(true);
                                                    }}
                                                >
                                                    <EditIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip> */}

                                            <Tooltip title="Delete">
                                                <IconButton size="small" color="error" onClick={() => handleDeleteConfirmation(coupon.id)}>
                                                    <DeleteIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Delete Confirmation Dialog */}
            <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete this coupon? This action cannot be undone.</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
                    <Button onClick={handleDelete} color="error" variant="contained">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>

            <AddCouponDialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                onSubmit={selectedCoupon ? handleEditCoupon : handleAddCoupon}
                initialValues={selectedCoupon}
            />

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert severity={snackbar.severity} sx={{ width: '100%' }} onClose={() => setSnackbar({ ...snackbar, open: false })}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default CouponManager;

const GET_COUPONS = gql`
    query GetCoupons($eventId: String!) {
        getCoupons(eventId: $eventId) {
            id
            CouponTitle
            CouponCode
            CouponValue
            CouponType
            ApplicableTimes
            UsedTimes
            Status
        }
    }
`;

const ADD_COUPON = gql`
    mutation AddCoupon($eventId: String!, $input: CouponInput!) {
        addCoupon(eventId: $eventId, input: $input) {
            status
            message
        }
    }
`;

const UPDATE_COUPON = gql`
    mutation UpdateCoupon($eventId: String!, $id: ID!, $input: CouponInput!) {
        updateCoupon(eventId: $eventId, id: $id, input: $input) {
            status
            message
        }
    }
`;

const DELETE_COUPON = gql`
    mutation DeleteCoupon($eventId: String!, $id: ID!) {
        deleteCoupon(eventId: $eventId, id: $id) {
            status
            message
        }
    }
`;
const TOGGLE_COUPON_STATUS = gql`
    mutation ToggleCouponStatus($eventId: String!, $id: ID!) {
        toggleCouponStatus(eventId: $eventId, id: $id) {
            status
            message
            coupon {
                id
                Status
            }
        }
    }
`;

// Remove UPDATE_COUPON mutation
