import * as React from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
    AppBar,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    LinearProgress,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Backspace } from '@mui/icons-material';
import AnimateButton from 'ui-component/extended/AnimateButton';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import SendEmail from 'Components/SendEmail';
import BulkPrint from 'Components/BulkPrint';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`
    };
}
export default function CorporateRegList() {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const { corporateId } = useParams();
    const location = useLocation();

    // Access the state passed during navigation
    const { state } = location;
    const corporateData = state && state.corporateData;

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };
    const { eventId } = useParams();
    const [search, setSearch] = React.useState('');
    const role = 'delegate';
    const { data, loading, error } = useQuery(GET_ME);
    const [tags, setTags] = React.useState([]);
    const { data: featureData, loading: featureLoading } = useQuery(GET_VERIFICATION_FEATURE, {
        variables: { eventId, role }
    });
    const {
        loading: laodingInFtechingRoles,
        error: errorInFtechingRoles,
        data: allowedRoleForCorporate
    } = useQuery(ALLOWED_ROLES_FOR_CORPORATE, {
        variables: { corporateId }
    });
    React.useEffect(() => {
        if (featureData && featureData.mailVerificationFeature) {
            let parsedData = [];
            try {
                parsedData = JSON.parse(featureData.mailVerificationFeature);
            } catch (err) {
                console.error('Error parsing the data:', err);
            }
            if (Array.isArray(parsedData)) {
                setTags(parsedData);
            }
        }
    }, [featureData]);

    if (loading || featureLoading || laodingInFtechingRoles) {
        return <LinearProgress />;
    }
    if (error || errorInFtechingRoles) {
        return <Typography>{errorInFtechingRoles.message}</Typography>;
    }

    return (
        <Stack spacing={2}>
            <MainCard>
                <Grid spacing={gridSpacing} container={true}>
                    <Grid item xs={12}>
                        <Stack direction={'row'} justifyContent="space-between" sx={{ bgcolor: 'background.paper', width: '100%', mb: 2 }}>
                            <Typography variant="h1" textAlign={'center'}>
                                {corporateData.company} Registered Data
                            </Typography>
                            {<SearchBox onSearch={setSearch} />}
                        </Stack>
                        <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
                            <AppBar position="relative" color="secondary">
                                <Tabs
                                    sx={{
                                        '& .Mui-selected': { background: '#05344f', color: 'white' },
                                        background: 'white',
                                        color: 'black'
                                    }}
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="secondary"
                                    textColor="inherit"
                                    variant="fullWidth"
                                    aria-label="full width tabs example"
                                >
                                    {allowedRoleForCorporate?.allowedRoleForCorporate?.map((role, index) => (
                                        <Tab
                                            key={index}
                                            sx={{ '& .Mui-selected': { background: '#05344f' }, border: '1px solid black' }}
                                            label={role}
                                            {...a11yProps(index)}
                                        />
                                    ))}
                                </Tabs>
                            </AppBar>
                            <SwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={value}
                                onChangeIndex={handleChangeIndex}
                            >
                                {allowedRoleForCorporate?.allowedRoleForCorporate?.map((role, index) => (
                                    <TabPanel value={value} index={index} dir={theme.direction}>
                                        <DataTable
                                            {...{ eventId, corporateId, search, role }}
                                            tags={tags}
                                            permissions={data.me?.permissions?.split(',') || []}
                                        />
                                    </TabPanel>
                                ))}
                            </SwipeableViews>
                        </Box>
                    </Grid>
                </Grid>
            </MainCard>
        </Stack>
    );
}

function SearchBox({ onSearch }) {
    const [search, setSearch] = React.useState('');
    React.useEffect(() => {
        const t = setTimeout(() => onSearch(search), 500);
        return () => clearTimeout(t);
    }, [search]);
    return (
        <TextField
            sx={{ width: 600 }}
            label="Search here..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                    <InputAdornment position="end">
                        {search && (
                            <IconButton onClick={() => setSearch('')}>
                                <Backspace />
                            </IconButton>
                        )}
                    </InputAdornment>
                )
            }}
        />
    );
}

function DataTable({ eventId, corporateId, role, search = '', permissions, tags }) {
    const limit = 100;
    let query, variables;
    const { data: me, loading: meLoading, error: meError } = useQuery(GET_ME);
    const userID = me ? me.me.id : 'null';

    if (tags?.includes('emailVerification')) {
        query = GET_VERIFIED_DATA_BY_CORPORATE;
        variables = {
            eventId,
            corporateId,
            role,
            status: 'accept',
            conditions: {
                limit,
                text: search,
                orderBy: 'created',
                order: 'desc'
            }
        };
    } else {
        query = GET_DATA_BY_CORPORATE;
        variables = {
            eventId,
            corporateId,
            role,
            conditions: {
                limit,
                text: search,
                orderBy: 'created',
                order: 'desc'
            }
        };
    }

    const { data, loading, error, refetch } = useQuery(query, { variables });
    const [selectedIds, setSelectedIds] = React.useState([]);

    const [updateField] = useMutation(UPDATE_APPLICANT_FIELD, {
        onCompleted: refetch,
        onError: (e) => console.log(e)
    });
    const [doDelete] = useMutation(DELETE_APPLICANT, { onCompleted: refetch });

    if (loading) {
        return <LinearProgress />;
    }
    if (error) {
        return <Typography color="error">{error.message}</Typography>;
    }
    return (
        <Stack spacing={2}>
            <Box>
                <DataGrid
                    sx={{ height: 600 }}
                    paginationMode="server"
                    pageSize={limit}
                    rowsPerPageOptions={[limit]}
                    onPageChange={(p) =>
                        refetch({
                            conditions: {
                                offset: p * limit,
                                limit,
                                orderBy: 'created',
                                order: 'desc'
                            }
                        })
                    }
                    rowCount={
                        tags.includes('emailVerification')
                            ? data?.verifiedApplicantsForCorporate?.length || 0
                            : data?.applicantsForCorporate?.length || 0
                    }
                    components={{
                        Toolbar: permissions.includes('exp-' + role) ? GridToolbar : ''
                    }}
                    columns={[
                        { field: 'id', width: 150 },

                        {
                            field: 'created',
                            width: 200,
                            valueFormatter: (row) => format(+row.value, 'dd-MMM-yyyy hh:mm a')
                        },
                        { field: 'regLink', width: 300, headerName: 'Registration Link' },
                        ...data?.form.fields.map((f) => ({
                            field: f.name,
                            width: 200,
                            editable: permissions.includes('edit-' + role)
                        })),
                        { field: 'isScanned', width: 150, headerName: 'Entry Status' },
                        { field: 'isPrinted', width: 150, headerName: 'Print Status' }
                    ]}
                    rows={
                        tags.includes('emailVerification')
                            ? data?.verifiedApplicantsForCorporate.map((row) => ({
                                  ...row,
                                  ...JSON.parse(row.data)
                              }))
                            : data?.applicantsForCorporate.map((row) => ({
                                  ...row,
                                  ...JSON.parse(row.data)
                              }))
                    }
                />
            </Box>
            <Box>
                <Stack direction="row" spacing={2}>
                    <AnimateButton>
                        <Button variant="contained" disabled={loading} onClick={() => refetch()}>
                            Refresh
                        </Button>
                    </AnimateButton>
                    {permissions.includes('admin') && permissions.includes('bulk-email') ? (
                        <SendEmail {...{ selectedIds, eventId, role }} />
                    ) : (
                        <div />
                    )}
                    {permissions.includes('admin') && permissions.includes('bulk-print') ? (
                        <BulkPrint {...{ selectedIds, eventId, role }} />
                    ) : (
                        <div />
                    )}
                </Stack>
            </Box>
        </Stack>
    );
}

const GET_DATA_BY_CORPORATE = gql`
    query ($eventId: String!, $role: ROLE!, $conditions: InputConditions, $corporateId: String) {
        applicantsForCorporate(eventId: $eventId, role: $role, conditions: $conditions, corporateId: $corporateId) {
            serial
            id
            created
            regLink
            data
            isScanned
            isPrinted
        }
        total: applicantsCount(eventId: $eventId, role: $role, conditions: $conditions)

        form(eventId: $eventId, role: $role) {
            fields {
                name
            }
        }
    }
`;
const GET_VERIFIED_DATA_BY_CORPORATE = gql`
    query ($eventId: String!, $role: ROLE!, $status: String, $conditions: InputConditions, $corporateId: String) {
        verifiedApplicantsForCorporate(
            eventId: $eventId
            role: $role
            status: $status
            conditions: $conditions
            corporateId: $corporateId
        ) {
            serial
            id
            created
            regLink
            data
        }
        total: verifiedApplicantsCount(eventId: $eventId, role: $role, status: $status, conditions: $conditions)

        form(eventId: $eventId, role: $role) {
            fields {
                name
            }
        }
    }
`;
const GET_FOOTFALL_DATA = gql`
    query ($eventId: String!, $role: ROLE!, $conditions: InputConditions) {
        applicants: footfall(eventId: $eventId, role: $role, conditions: $conditions) {
            serial
            id
            created
            staffId
            role
            direction
            data
        }
        total: footfallCount(eventId: $eventId, role: $role, conditions: $conditions)
        form(eventId: $eventId, role: $role) {
            fields {
                name
            }
        }
    }
`;
const GET_ONSITE_PRINT_DATA = gql`
    query ($eventId: String!, $role: ROLE!, $conditions: InputConditions, $getData: [String]) {
        applicants: onsitePrint(eventId: $eventId, role: $role, conditions: $conditions, getData: $getData) {
            serial
            id
            created
            staffId
            role
            direction
            data
        }
        total: onsitePrintCount(eventId: $eventId, role: $role, conditions: $conditions, getData: $getData)
        form(eventId: $eventId, role: $role) {
            fields {
                name
            }
        }
    }
`;

const UPDATE_APPLICANT_FIELD = gql`
    mutation ($eventId: String!, $role: ROLE!, $id: String!, $field: String!, $value: String!) {
        result: updateApplicantField(eventId: $eventId, role: $role, id: $id, field: $field, value: $value)
    }
`;

const DELETE_APPLICANT = gql`
    mutation ($eventId: String!, $role: ROLE!, $id: String!) {
        result: deleteApplicant(eventId: $eventId, role: $role, id: $id)
    }
`;

export const GET_VERIFICATION_FEATURE = gql`
    query ($eventId: String!, $role: ROLE!) {
        mailVerificationFeature(eventId: $eventId, role: $role)
    }
`;
const GET_ME = gql`
    query {
        me {
            id
            permissions
        }
    }
`;

const ALLOWED_ROLES_FOR_CORPORATE = gql`
    query allowedRoleForCorporate($corporateId: String!) {
        allowedRoleForCorporate(corporateId: $corporateId)
    }
`;
