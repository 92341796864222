import React, { useState } from 'react';
import { useQuery, gql, useMutation } from '@apollo/client';
import {
    Card,
    CardContent,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
    Button,
    Snackbar,
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import LeadGenCard from './LeadGenCard';
import LeadGenStaffCard from './LeadGenStaffCard';
import { Stack } from '@mui/system';
import { Backspace, Edit } from '@mui/icons-material';
import AddCompanyDialog from './AddCompanyDialog';
import AddIcon from '@mui/icons-material/Add';
import ExhibitorRegCard from './ExhibitorRegCard';
import UpgradeIcon from '@mui/icons-material/Upgrade';

const GET_SPONSORSREGISTERED_LIST = gql`
    query GetSponsorsRegisteredData($eventId: String!, $searchTerm: String, $role: String) {
        getSponsorsRegisteredData(eventId: $eventId, searchTerm: $searchTerm, role: $role)
    }
`;

const ADD_COMPANY = gql`
    mutation AddCompany($formDetail: String!, $role: String!) {
        addCompany(formDetail: $formDetail, role: $role) {
            status
            message
        }
    }
`;

const UPDATE_REGISTRATION_LIMIT = gql`
    mutation UpdateCompany($id: String!, $field: String!, $value: String!, $role: String) {
        updateCompany(id: $id, field: $field, value: $value, role: $role) {
            status
            message
        }
    }
`;

function SearchBox({ onSearch }) {
    const [search, setSearch] = React.useState('');
    React.useEffect(() => {
        const t = setTimeout(() => onSearch(search), 500);
        return () => clearTimeout(t);
    }, [search]);
    return (
        <TextField
            sx={{ width: 300 }}
            label="Search here..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                    <InputAdornment position="end">
                        {search && (
                            <IconButton onClick={() => setSearch('')}>
                                <Backspace />
                            </IconButton>
                        )}
                    </InputAdornment>
                )
            }}
        />
    );
}

export default function SponsorsReg() {
    const { eventId } = useParams();
    const [searchTerm, setSearchTerm] = useState('');
    const role = 'Exhibitors';
    const { loading, error, data } = useQuery(GET_SPONSORSREGISTERED_LIST, {
        variables: { eventId, searchTerm, role }
    });
    const navigate = useNavigate();
    const [openDialog, setOpenDialog] = useState(false);
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const [newLimit, setNewLimit] = useState('');
    const [selectedSponsor, setSelectedSponsor] = useState(null);

    const [addCompany] = useMutation(ADD_COMPANY);
    const [updateRegistrationLimit] = useMutation(UPDATE_REGISTRATION_LIMIT);

    const sponsorsRegisteredData = (data && data?.getSponsorsRegisteredData && JSON.parse(data?.getSponsorsRegisteredData)) || [];

    const handleAddCompany = async (formData) => {
        try {
            const response = await addCompany({
                variables: {
                    formDetail: JSON.stringify(formData),
                    role
                },
                refetchQueries: [
                    {
                        query: GET_SPONSORSREGISTERED_LIST,
                        variables: { eventId, searchTerm, role }
                    }
                ]
            });

            if (response.data.addCompany.status === true) {
                setSnackbar({
                    open: true,
                    message: response.data.addCompany.message,
                    severity: 'success'
                });
                setOpenDialog(false);
            } else {
                setSnackbar({
                    open: true,
                    message: response.data.addCompany.message,
                    severity: 'error'
                });
            }
        } catch (error) {
            setSnackbar({
                open: true,
                message: error.message,
                severity: 'error'
            });
        }
    };

    const handleUpdateLimit = async () => {
        if (parseInt(newLimit) <= selectedSponsor.registrationData.totalAllowedRegistration) {
            setSnackbar({
                open: true,
                message: `New limit must be greater than ${selectedSponsor.registrationData.totalAllowedRegistration}`,
                severity: 'error'
            });
            return;
        }

        try {
            const response = await updateRegistrationLimit({
                variables: {
                    id: selectedSponsor.sponsorId,
                    field: 'allowedRegistrations',
                    value: newLimit,
                    role
                },
                refetchQueries: [
                    {
                        query: GET_SPONSORSREGISTERED_LIST,
                        variables: { eventId, searchTerm, role }
                    }
                ]
            });

            if (response.data.updateCompany.status === true) {
                setSnackbar({
                    open: true,
                    message: response.data.updateCompany.message,
                    severity: 'success'
                });
                setUpdateDialogOpen(false);
                setNewLimit('');
            } else {
                setSnackbar({
                    open: true,
                    message: response.data.updateCompany.message,
                    severity: 'error'
                });
            }
        } catch (error) {
            setSnackbar({
                open: true,
                message: error.message,
                severity: 'error'
            });
        }
    };

    return (
        <div>
            {/* <Typography variant="h2" gutterBottom textAlign="center" sx={{ marginBottom: '20px' }}>
                Sponsors Registered Data
            </Typography> */}
            <Stack
                direction={'row'}
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    // bgcolor: 'background.paper',
                    width: '100%',
                    mb: 5
                }}
            >
                <Typography variant="h1" textAlign={'center'}>
                    Exhibitors Data
                </Typography>
                <Stack direction="row" spacing={2} alignItems="center">
                    <SearchBox onSearch={setSearchTerm} />
                    <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setOpenDialog(true)}>
                        Add Exhibitors
                    </Button>
                </Stack>
            </Stack>
            <Grid container spacing={2} justifyContent="center">
                {sponsorsRegisteredData.length > 0 ? (
                    sponsorsRegisteredData.map((sponsorData, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={2} key={index}>
                            <ExhibitorRegCard
                                sponsorData={sponsorData}
                                onUpdateLimit={(data) => {
                                    setSelectedSponsor(data);
                                    setUpdateDialogOpen(true);
                                }}
                            />
                        </Grid>
                    ))
                ) : (
                    <Typography textAlign="center">Oops! No data found</Typography>
                )}
            </Grid>

            <AddCompanyDialog open={openDialog} onClose={() => setOpenDialog(false)} onSubmit={handleAddCompany} />

            <Dialog open={updateDialogOpen} onClose={() => setUpdateDialogOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>
                    <Stack direction="row" alignItems="center">
                        <UpgradeIcon sx={{ marginRight: 1 }} />
                        <Typography variant="h6">Update Registration Limit</Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        Update the registration limit for {selectedSponsor?.Company}. Please enter a value greater than{' '}
                        {selectedSponsor?.registrationData?.totalAllowedRegistration}.
                    </Typography>
                    <TextField
                        margin="dense"
                        label="New Registration Limit"
                        type="number"
                        fullWidth
                        variant="outlined"
                        value={newLimit}
                        onChange={(e) => setNewLimit(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setUpdateDialogOpen(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleUpdateLimit} color="primary">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={() => setSnackbar({ ...snackbar, open: false })}>
                <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
}
