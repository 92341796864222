import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';

const validationSchema = Yup.object({
    CouponTitle: Yup.string().required('Coupon title is required'),
    CouponCode: Yup.string().required('Coupon code is required'),
    CouponValue: Yup.number().required('Coupon value is required').min(0, 'Coupon value must be greater than or equal to 0'),
    CouponType: Yup.string().oneOf(['%', 'static']).required('Coupon type is required'),
    ApplicableTimes: Yup.number().required('Applicable times is required').min(1, 'Must be at least 1'),
    Status: Yup.string().required('Status is required')
});

export default function AddCouponDialog({ open, onClose, onSubmit, initialValues }) {
    const [codeError, setCodeError] = useState('');
    const formik = useFormik({
        initialValues: initialValues || {
            CouponTitle: '',
            CouponCode: '',
            CouponValue: '',
            CouponType: '%',
            ApplicableTimes: '',
            Status: 'active'
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setCodeError('');
                await onSubmit(values);
                setSubmitting(false);
                onClose();
            } catch (error) {
                setSubmitting(false);
                if (error.message.includes('already exists')) {
                    setCodeError('This coupon code is already in use');
                }
            }
        }
    });

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>{initialValues ? 'Edit Coupon' : 'Add New Coupon'}</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            fullWidth
                            name="CouponTitle"
                            label="Coupon Title"
                            value={formik.values.CouponTitle}
                            onChange={formik.handleChange}
                            error={formik.touched.CouponTitle && Boolean(formik.errors.CouponTitle)}
                            helperText={formik.touched.CouponTitle && formik.errors.CouponTitle}
                        />
                        <TextField
                            fullWidth
                            name="CouponCode"
                            label="Coupon Code"
                            value={formik.values.CouponCode}
                            onChange={formik.handleChange}
                            error={!!codeError || (formik.touched.CouponCode && Boolean(formik.errors.CouponCode))}
                            helperText={codeError || (formik.touched.CouponCode && formik.errors.CouponCode)}
                        />
                        <TextField
                            fullWidth
                            name="CouponValue"
                            label="Coupon Value"
                            type="number"
                            value={formik.values.CouponValue}
                            onChange={formik.handleChange}
                            error={formik.touched.CouponValue && Boolean(formik.errors.CouponValue)}
                            helperText={formik.touched.CouponValue && formik.errors.CouponValue}
                        />
                        {/* <TextField
                            fullWidth
                            name="CouponType"
                            label="Coupon Type"
                            select
                            SelectProps={{
                                native: true
                            }}
                            value={formik.values.CouponType}
                            onChange={formik.handleChange}
                            error={formik.touched.CouponType && Boolean(formik.errors.CouponType)}
                            helperText={formik.touched.CouponType && formik.errors.CouponType}
                        >
                            <option value="static">Static</option>
                            <option value="%">Percentage</option>
                        </TextField> */}
                        <TextField
                            fullWidth
                            name="ApplicableTimes"
                            label="Applicable Times"
                            type="number"
                            value={formik.values.ApplicableTimes}
                            onChange={formik.handleChange}
                            error={formik.touched.ApplicableTimes && Boolean(formik.errors.ApplicableTimes)}
                            helperText={formik.touched.ApplicableTimes && formik.errors.ApplicableTimes}
                        />
                        <TextField
                            fullWidth
                            name="Status"
                            label="Status"
                            select
                            SelectProps={{
                                native: true
                            }}
                            value={formik.values.Status}
                            onChange={formik.handleChange}
                            error={formik.touched.Status && Boolean(formik.errors.Status)}
                            helperText={formik.touched.Status && formik.errors.Status}
                        >
                            <option value="active">Active</option>
                            <option value="expired">Inactive</option>
                        </TextField>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                        {initialValues ? 'Update Coupon' : 'Add Coupon'}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
