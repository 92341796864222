import { Button, Card, CardContent, Divider, Stack, Tooltip, Typography, IconButton } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import UpgradeIcon from '@mui/icons-material/Upgrade';

export default function ExhibitorRegCard({ sponsorData, onUpdateLimit }) {
    const navigate = useNavigate();
    console.log(sponsorData);

    const totalAllowedRegistration = sponsorData.registrationData?.totalAllowedRegistration || 0;

    return (
        <>
            <Card variant="outlined">
                <CardContent>
                    <Stack direction={'row'} justifyContent={'center'} padding={1}>
                        {/* <Typography variant="body2" justifyContent={'start'} fontWeight={700} color="text.secondary">
                            Company:
                        </Typography> */}
                        <Typography variant="span" justifyContent={'center'} fontWeight={'bold'}>
                            {sponsorData?.company || sponsorData.Company}
                        </Typography>
                    </Stack>
                    <Divider />

                    <Stack direction={'row'} justifyContent={'space-between'} padding={1}>
                        <Typography variant="body2" justifyContent={'start'} fontWeight={700} color="text.secondary">
                            Total No Of Registerations:
                        </Typography>
                        <Typography variant="span" justifyContent={'end'} sx={{ cursor: 'pointer' }}>
                            <Tooltip title="completed registration">{sponsorData.registrationData?.total} </Tooltip> /{' '}
                            <Tooltip title="allowed Registration"> {totalAllowedRegistration} </Tooltip>
                        </Typography>
                    </Stack>
                    <Divider />
                    <Stack direction={'row'} justifyContent={'space-between'} padding={1}>
                        <Typography variant="body2" justifyContent={'start'} fontWeight={700} color="text.secondary">
                            Total No of Entry:
                        </Typography>
                        <Typography variant="span" justifyContent={'end'}>
                            {sponsorData.scannedData.total.entry}
                        </Typography>
                    </Stack>
                    <Divider />
                    <Stack direction={'row'} justifyContent={'space-between'} padding={1}>
                        <Typography variant="body2" justifyContent={'start'} fontWeight={700} color="text.secondary">
                            Total No of Prints:
                        </Typography>
                        <Typography variant="span" justifyContent={'end'}>
                            {sponsorData.printedData.total}
                        </Typography>
                    </Stack>
                    <Divider />

                    <Stack direction="row" justifyContent="space-between" padding={1}>
                        <Stack spacing={2} mt={2} justifyContent={'center'}>
                            <Button
                                variant={'contained'}
                                color="secondary"
                                onClick={() => navigate(`${sponsorData.sponsorId}`, { state: { sponsorData } })}
                            >
                                View Data
                            </Button>
                        </Stack>
                        <IconButton onClick={() => onUpdateLimit(sponsorData)}>
                            <Tooltip title="Upgrade Registration Limit">
                                <UpgradeIcon />
                            </Tooltip>
                        </IconButton>
                    </Stack>
                </CardContent>
            </Card>
        </>
    );
}
