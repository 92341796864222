import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Box } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
    firstName: Yup.string().required('Contact person name is required'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    Company: Yup.string().required('Company name is required'),
    allowedRegistrations: Yup.number()
        .required('Allowed registrations for VIP is required')
        .min(1, 'Must be at least 1')
        .integer('Must be a whole number'),
    allowed_no_of_staff: Yup.number()
        .required('Allowed registrations for Delegate is required')
        .min(1, 'Must be at least 1')
        .integer('Must be a whole number'),
    stallNo: Yup.string().required('Booth number is required')
});

export default function AddSponsorDialog({ open, onClose, onSubmit }) {
    const formik = useFormik({
        initialValues: {
            firstName: '',
            email: '',
            Company: '',
            allowedRegistrations: '',
            allowed_no_of_staff: '',
            stallNo: ''
        },
        validationSchema,
        onSubmit: (values) => {
            onSubmit(values);
        }
    });

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Add New Sponsor</DialogTitle>
            <form onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            fullWidth
                            name="firstName"
                            label="Contact Person Name"
                            value={formik.values.contactPersonName}
                            onChange={formik.handleChange}
                            error={formik.touched.contactPersonName && Boolean(formik.errors.contactPersonName)}
                            helperText={formik.touched.contactPersonName && formik.errors.contactPersonName}
                        />
                        <TextField
                            fullWidth
                            name="email"
                            label="Email"
                            type="email"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                        <TextField
                            fullWidth
                            name="Company"
                            label="Company Name"
                            value={formik.values.company}
                            onChange={formik.handleChange}
                            error={formik.touched.company && Boolean(formik.errors.company)}
                            helperText={formik.touched.company && formik.errors.company}
                        />
                        <TextField
                            fullWidth
                            name="allowedRegistrations"
                            label="Allowed Registrations for VIP"
                            type="number"
                            value={formik.values.allowedRegistrationsVIP}
                            onChange={formik.handleChange}
                            error={formik.touched.allowedRegistrationsVIP && Boolean(formik.errors.allowedRegistrationsVIP)}
                            helperText={formik.touched.allowedRegistrationsVIP && formik.errors.allowedRegistrationsVIP}
                        />
                        <TextField
                            fullWidth
                            name="allowed_no_of_staff"
                            label="Allowed Registrations for Delegate"
                            type="number"
                            value={formik.values.allowedRegistrationsDelegate}
                            onChange={formik.handleChange}
                            error={formik.touched.allowedRegistrationsDelegate && Boolean(formik.errors.allowedRegistrationsDelegate)}
                            helperText={formik.touched.allowedRegistrationsDelegate && formik.errors.allowedRegistrationsDelegate}
                        />
                        <TextField
                            fullWidth
                            name="stallNo"
                            label="Booth Number"
                            value={formik.values.stallNo}
                            onChange={formik.handleChange}
                            error={formik.touched.stallNo && Boolean(formik.errors.stallNo)}
                            helperText={formik.touched.stallNo && formik.errors.stallNo}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button type="submit" variant="contained" color="primary">
                        Add Sponsor
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
